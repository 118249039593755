import * as React from 'react';
import Box from '@mui/material/Box';
import { isTokenValid } from '../../utils/isTokenValid';
import { useNavigate } from 'react-router-dom';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import axios from 'axios';

const GET_USER_BOOKS_URL = '/book/get-user-books'
const GET_IMAGE_URL = '/book/illustrations/'

export default function Account() {
  const navigate = useNavigate()

  const [userBooks, setUserBooks] = React.useState([])
  const [isLoadingUserBooks, setIsLoadingUserBooks] = React.useState(true)

  const getUserBooks = () => {
    axios
      .get(GET_USER_BOOKS_URL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      })
      .then((response) => {
        setUserBooks(response.data)
        setIsLoadingUserBooks(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    isTokenValid().then((isValid) => {
      if (!isValid) navigate('/')
    })
  }, [])

  React.useEffect(() => {
    if (isLoadingUserBooks === true) {
      getUserBooks()
    }
  }, [isLoadingUserBooks])

  return (
    <Box display="flex" alignItems="center" flexDirection="column" marginTop="100px">
      <Typography variant="h5" gutterBottom paddingBottom={2}>
        Mon compte
      </Typography>
      <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
        {userBooks.length > 0 ?  
          userBooks.map((book, index) => 
            <>
              <ListItem alignItems="flex-start" onClick={() => navigate(`/books/${book.id}`)} sx={{ cursor: 'pointer' }}>
                <ListItemAvatar>
                  <Avatar src={GET_IMAGE_URL + book.bookParts?.[0]?.illustrations?.[0]?.id} alt={`Illustration ${index}`} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${book.name}, ${book.age} ans`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: 'text.primary', display: 'inline' }}
                      >
                        {`Thème${book.themes.length > 1 ? 's' : ''} : ` + book.themes.map(t => t.bookThemeId).join(', ') + " — "}
                      </Typography>
                      {"Créé le : " + book.createdAt}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          )
        : <></>}
        
      </List>
    </Box>
  );
}