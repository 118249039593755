import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import CreateBook from './pages/creation/CreateBook';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import BookList from './pages/books/BookList';
import Book from './pages/books/Book';
import TopBar from './pages/AppBar';
import VerifyToken from './pages/auth/VerifyToken';
import Landing from './pages/public/Landing';
import Account from './pages/account/Account';
import BuyBook from './pages/books/BuyBook';
// import reportWebVitals from './reportWebVitals';

ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <TopBar />
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/account' element={<Account />} />
          <Route path='/create-book' element={<CreateBook />} />
          <Route path='/books/:id' element={<Book />} />
          <Route path='/books/:id/buy' element={<BuyBook />} />
          <Route path='/verify-token' element={<VerifyToken />} />
        </Routes>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// reportWebVitals();