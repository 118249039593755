import * as React from 'react'
import { Box, CircularProgress, Grid, Skeleton, Typography } from "@mui/material"
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import axios from 'axios';

const REGENERATE_ILLUSTRATION_URL = '/book/regenerate-illustration'
const SET_CHOSEN_ILLUSTRATION = '/book/set-chosen-illustration'
const GET_IMAGE_URL = '/book/illustrations/'
const IMAGE_SIZE = 300
const MAX_IMAGE_REGEN = 5

export default function Book({ storyParts, getBook, setIsLoading }) {
  const [storyPartRegenerating, setStoryPartRegenerating] = React.useState(undefined)
  const canDisplayRegenerateIllustration = storyParts.length + MAX_IMAGE_REGEN > storyParts.map(p => p.illustrations).flat().length
  console.log(storyParts.length + MAX_IMAGE_REGEN, storyParts.map(p => p.illustrations).flat().length)

  const regenerateIllustration = (bookPartId) => {
    const authToken = localStorage.getItem('authToken')
    setIsLoading(true)
    axios
      .post(REGENERATE_ILLUSTRATION_URL, {
        bookPartId,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
      .then((response) => {
        console.log('response')
        console.log(response)
        setStoryPartRegenerating(undefined)
        getBook()
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
        setStoryPartRegenerating(undefined)
      })
  }

  const setSelectedIllustration = (bookPartId, chosenIllustrationId) => {
    const authToken = localStorage.getItem('authToken')
    setIsLoading(true)
    axios
      .post(SET_CHOSEN_ILLUSTRATION, {
        bookPartId,
        chosenIllustrationId
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
      .then((response) => {
        console.log('response')
        console.log(response)
        getBook()
      })
      .catch((error) => {
        console.log('error')
        console.log(error)
      })
  }

  return (
    <>
      {storyParts.length > 0 
        ? storyParts.map((storyPart, index) => 
            <Grid 
              key={index}
              container
              maxWidth="1000px"
              alignItems="center"
            >
              <Grid item xs={12} sm={7} padding={3}>
                <Typography align='center' variant="body1" gutterBottom>
                  {storyPart.storyGenerated}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} padding={2}>
                {!!storyPart.illustrations?.[0]?.id && storyPart.illustrations?.[0]?.generationStatus === 'DONE'
                  ? <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                      <img 
                        src={GET_IMAGE_URL + storyPart.illustrations.find(i => i.isIllustrationChosen === true).id} 
                        alt={`Illustration ${index}`} 
                        height={IMAGE_SIZE} 
                        style={{ borderRadius: 5 }}
                      />
                      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                        {storyPart.illustrations.map(
                          i => <img 
                            src={GET_IMAGE_URL + i.id} 
                            alt={`Illustration`} 
                            height={30} 
                            style={{ 
                              borderRadius: 5, 
                              ...!i.isIllustrationChosen && { cursor: 'pointer' },
                              ...!i.isIllustrationChosen && { filter: 'grayscale(100%)' } 
                            }} 
                            onClick={() => setSelectedIllustration(storyPart.id, i.id)}
                            />
                        )}
                        {canDisplayRegenerateIllustration && <Box sx={{ cursor: 'pointer' }} onClick={() => {
                          setStoryPartRegenerating(index)
                          regenerateIllustration(storyPart.id)
                        }}>
                          {storyPartRegenerating === index ? <CircularProgress size={20} /> : <></>}
                          {storyPartRegenerating === undefined ? <ModelTrainingIcon /> : <></>}
                        </Box>}
                      </Box>
                    </Box>
                  : <Skeleton variant="rounded" height={IMAGE_SIZE} />
                }
              </Grid>
            </Grid>
          )
        : <></>
      }
    </>
  )
}