import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import logo from '../images/logo.png'
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useNavigate } from 'react-router-dom';

import { isTokenValid } from '../utils/isTokenValid';
import { AccountCircle } from '@mui/icons-material';

const ITEM_HEIGHT = 48

export default function TopBar() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isAuthTokenValid, setIsAuthTokenValid] = React.useState(false)

  const open = Boolean(anchorEl);
  React.useEffect(() => {
    isTokenValid().then((isValid) => setIsAuthTokenValid(isValid))
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <Box sx={{
      position: "fixed",
      flexGrow: 1 ,
      top: "0px",
      backgroundColor: "rgba(255, 255, 255, 0.5)", 
      backdropFilter: "blur(10px)",
      borderBottom: "1px solid lightgray",
      height: { display: { xs: "56", sm: "64px" } }, 
      width: "100%",
      zIndex: 1000
    }}>
      <Toolbar sx={{ gap: 4, display: "flex", justifyContent: "space-between" }}>
        <img
          src={logo}
          alt="logo"
          height="25px"
          style={{ marginTop: '-6px', cursor: 'pointer' }}
          onClick={() => {
            navigate("/")
          }}
        />
        <Box
          sx={{ display: { xs: 'block', sm: 'none' } }}
          position="absolute"
          right={2}
          padding={2}
        >
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {isAuthTokenValid ?
              <>
                <MenuItem onClick={() => {
                  navigate("/create-book")
                  handleClose()
                }}>
                  Je crée mon livre
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate("/account")
                  handleClose()
                }}>
                  Mon compte
                </MenuItem>
              </>
              : <></>}


          </Menu>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {isAuthTokenValid ?
            <Button
              sx={{ color: 'black', paddingX: 2, textWrap: 'nowrap' }}
              onClick={() => navigate("/create-book")}
            >
              Créer un livre
            </Button>
            : <></>}
          {isAuthTokenValid ?
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => navigate("/account")}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            : <></>}
        </Box>
      </Toolbar>
    </Box>
  );
}