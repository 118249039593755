import * as React from 'react'
import { Box, Button, CircularProgress, Grid, Skeleton, Typography } from "@mui/material"
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import StoryDisplay from './components/StoryDisplay'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const GET_BOOK_URL = '/book/'
const IMAGE_SIZE = 300

export default function Book() {
  const { id } = useParams()

  const [isLoading, setIsLoading] = React.useState(true)
  const [isLazyLoading, setIsLazyLoading] = React.useState(false)
  const [generatedStoryParts, setGeneratedStoryParts] = React.useState([])

  const navigate = useNavigate()

  const getBook = React.useCallback(() => {
    setIsLazyLoading(true)
    const getBookUrl = GET_BOOK_URL + id
    axios
      .get(getBookUrl, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (!!response.data.bookParts && response.data.bookParts.length > 0) {
          setGeneratedStoryParts(response.data.bookParts)
          setIsLoading(false)
          setIsLazyLoading(false)
        } else {
          setTimeout(getBook, 5000)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [id])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    getBook()
  }, [])

  // TODO: replace by websocket
  React.useEffect(() => {
    if(generatedStoryParts.some(p => p.illustrations.length < 1 || p.illustrations?.[0]?.generationStatus !== 'DONE')) {
      setTimeout(getBook, 5000)
    }
  }, [generatedStoryParts, getBook])

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        flexWrap="wrap"
        padding="50px"
        gap="20px"
        marginTop="64px"
        marginBottom="64px"
      >
        {isLoading 
          ? Array.from(Array(8), (e, i) => (
              <Grid
                key={i}
                container
                maxWidth="1000px"
                alignItems="center"
              >
                <Grid item xs={12} sm={7} padding={3}>
                  <Typography align='center' variant="body1" gutterBottom>
                  {Array.from(Array(6), (e, i) => (
                    <Skeleton animation="wave" />
                  ))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Skeleton animation="wave" variant="rounded" height={IMAGE_SIZE} width={IMAGE_SIZE} />
                </Grid>
              </Grid>
            ))
          : <></>
        }
        <StoryDisplay storyParts={generatedStoryParts} getBook={getBook} setIsLoading={setIsLazyLoading} />
      </Box>
      <Box 
        sx={{ 
          position: "fixed",
          backgroundColor: "rgba(255, 255, 255, 0.5)", 
          backdropFilter: "blur(10px)",
          borderTop: "1px solid lightgray",
          bottom: "0px", 
          height: "64px", 
          width: "100%",
          zIndex: "100",
        }}>
        <Box
          display="flex"
          height="100%"
          flexDirection="row"
          paddingX="20px"
          alignItems="center"
          gap={3}
          justifyContent="space-between"
        >
          <Button variant="outlined" onClick={() => navigate(`/create-book`)} startIcon={<NavigateBeforeIcon />}>
            Retour
          </Button>
          <Box display='flex' flexDirection="row" alignItems="center" gap={2}>
            {isLoading || isLazyLoading ? <CircularProgress size={20} /> : <></>}
            <Button variant="outlined" onClick={() => navigate(`/books/${id}/buy`)} endIcon={<NavigateNextIcon />} disabled={isLoading || isLazyLoading}>
              Commander
            </Button>
          </Box>
        </Box>
    </Box>
   </>
  )
}