import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export default function BuyBook() {
  const { id } = useParams()
  const navigate = useNavigate()
  
  const getPdf = () => {
    const authToken = localStorage.getItem('authToken')
    
    axios
      .post(`/payment/create-checkout-session-pdf-book`, {
          bookId: id
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        })
      .then((response) => {
        console.log(response.data)
        window.open(response.data, "_self")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getPrintBook = () => {
    const authToken = localStorage.getItem('authToken')
    
    axios
      .post(`/payment/create-checkout-session-print-book`, {
          bookId: id
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        })
      .then((response) => {
        console.log(response.data)
        window.open(response.data, "_self")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Grid 
        container 
        marginTop="64px" 
        padding="20px" 
        paddingY="80px" 
        gap={3} 
        display="flex" 
        flexDirection="row" 
        justifyContent="center"
      >
        <Grid item onClick={getPdf} sx={{
          border: "1px solid lightgray",
          width: '350px',
          padding: 3,
          paddingTop: 6,
          borderRadius: 3,
          cursor: 'pointer'
        }}>
          <Typography gutterBottom variant="h6" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif' fontWeight={700}>
            Votre livre en PDF
          </Typography>
          <Typography variant="h3" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif' fontWeight={900}>
            1€
          </Typography>
          <Box paddingY={3} width="100%">
            <Button variant="outlined" fullWidth onClick={getPdf}>
              Acheter
            </Button>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" paddingBottom={1}>
            <CheckCircleIcon sx={{ height: '20px', color: 'gray' }} />
            <Typography variant="body1" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif'>
              Téléchargeable immédiatement
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" paddingBottom={1}>
            <CheckCircleIcon sx={{ height: '20px', color: 'gray' }} />
            <Typography variant="body1" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif'>
              Aucun filigrane
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" paddingBottom={1}>
            <CheckCircleIcon sx={{ height: '20px', color: 'gray' }} />
            <Typography variant="body1" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif'>
              Images en haute qualité
            </Typography>
          </Box>
          
        </Grid>
        <Grid item onClick={getPrintBook} sx={{
          border: "1px solid lightgray",
          width: '350px',
          padding: 3,
          paddingTop: 6,
          borderRadius: 3,
          cursor: 'pointer'
        }}>
          <Typography gutterBottom variant="h6" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif' fontWeight={700}>
            Votre livre chez vous
          </Typography>
          <Box display="flex" flexDirection="row" gap={3} alignItems="center">
            <Typography variant="h3" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif' fontWeight={900}>
              35€
            </Typography>
            <Box>
              <Typography variant="body2" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif' color="gray">
                Livraison
              </Typography>
              <Typography variant="body2" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif' fontWeight={700} color="gray">
                incluse
              </Typography>
            </Box>
          </Box>
          <Box paddingY={3} width="100%">
            <Button variant="outlined" fullWidth onClick={getPrintBook}>
              Acheter
            </Button>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" paddingBottom={1}>
            <CheckCircleIcon sx={{ height: '20px', color: 'gray' }} />
            <Typography variant="body1" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif'>
              Livre 216mm x 216mm
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" paddingBottom={1}>
            <CheckCircleIcon sx={{ height: '20px', color: 'gray' }} />
            <Typography variant="body1" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif'>
              Reliure couture
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" paddingBottom={1}>
            <CheckCircleIcon sx={{ height: '20px', color: 'gray' }} />
            <Typography variant="body1" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif'>
              Images et couleurs de haute qualité
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center" paddingBottom={1}>
            <CheckCircleIcon sx={{ height: '20px', color: 'gray' }} />
            <Typography variant="body1" fontFamily='-apple-system, "system-ui", "Segoe UI", sans-serif'>
              Livré en 10 jours ouvrés
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box 
        sx={{ 
          position: "fixed",
          backgroundColor: "rgba(255, 255, 255, 0.5)", 
          backdropFilter: "blur(10px)",
          borderTop: "1px solid lightgray",
          bottom: "0px", 
          height: "64px", 
          width: "100%",
          zIndex: "100",
        }}>
        <Box
          display="flex"
          height="100%"
          flexDirection="row"
          paddingX="20px"
          alignItems="center"
          gap={3}
          justifyContent="flex-start"
        >
          <Button variant="outlined" onClick={() => navigate(`/books/${id}`)} startIcon={<NavigateBeforeIcon />}>
            Retour
          </Button>
        </Box>
    </Box>
  </>
  );
}